import { render, staticRenderFns } from "./AllowedEquipments.vue?vue&type=template&id=34f9a3e2&scoped=true&"
import script from "./AllowedEquipments.vue?vue&type=script&lang=js&"
export * from "./AllowedEquipments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f9a3e2",
  null
  
)

export default component.exports