<template>
  <div class="step-body step-2">
    <!-- radio box -->
    <div class="form-wrapper">
      <base-input>
        <el-radio-group v-model="customerSelectionMethod">
          <el-radio :label="CUSTOMER_CHOICE_SELECT">
            {{ $t("BOOKINGS.EXISTING_CUSTOMER") }}
          </el-radio>
          <el-radio :label="CUSTOMER_CHOICE_CREATE">
            {{ $t("BOOKINGS.CREATE_CUSTOMER") }}
          </el-radio>
        </el-radio-group>
      </base-input>
    </div>

    <div
      class="form-wrapper"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_SELECT"
    >
      <base-input>
        <customer-selector
          :customer="booking.customer?.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="booking.organization?.id"
          @customerChanged="
            (customerId, customer) => {
              booking.customer.id = customerId;
              selectedCustomer = customer;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.customer" />
    </div>

    <div
      class="form-wrapper width_1-2"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :label="`${$t('CUSTOMERS.LAST_NAME')} (*)`"
        :placeholder="`${$t('CUSTOMERS.LAST_NAME')}`"
        v-model="customerModel.lastname"
      ></base-input>
      <validation-error :errors="apiValidationErrors.lastname" />
    </div>

    <div
      class="form-wrapper width_1-2"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :label="`${$t('CUSTOMERS.FIRST_NAME')} (*)`"
        :placeholder="`${$t('CUSTOMERS.FIRST_NAME')}`"
        v-model="customerModel.firstname"
      ></base-input>
      <validation-error :errors="apiValidationErrors.firstname" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :label="`${$t('CUSTOMERS.EMAIL')} (*)`"
        :placeholder="`${$t('CUSTOMERS.EMAIL')}`"
        v-model="customerModel.email"
        type="email"
      ></base-input>
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <div
      class="form-wrapper width_1 equal-item phone"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :label="`${$t('CUSTOMERS.PHONE')}`"
        :placeholder="`${$t('CUSTOMERS.PHONE')}`"
      >
        <phone-number-input
          :phoneNumber="customerModel.phone"
          @phoneNumberChanged="
            (phone) => {
              customerModel.phone = phone;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.phone" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :label="`${$t('CUSTOMERS.COUNTRY')} (*)`"
        :placeholder="$t('CUSTOMERS.COUNTRY')"
      >
        <country-selector
          :country="customerModel.country"
          :filterable="true"
          :showAll="false"
          @countryChanged="
            (country) => {
              customerModel.country = country;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :label="`${$t('CUSTOMERS.ADDRESS')} (*)`"
        :placeholder="$t('COMMON.ADDRESS')"
        v-model="customerModel.address"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.address" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input :placeholder="$t('COMMON.CITY')" v-model="customerModel.city">
      </base-input>
      <validation-error :errors="apiValidationErrors.city" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input :placeholder="$t('COMMON.CITY')" v-model="customerModel.city">
        <state-selector
          :country="customerModel.country"
          :state="customerModel.state"
          :filterable="true"
          :showAll="false"
          @stateChanged="
            (state) => {
              customerModel.state = state;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.city" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="customerSelectionMethod === CUSTOMER_CHOICE_CREATE"
    >
      <base-input
        :placeholder="$t('COMMON.ZIPCODE')"
        v-model="customerModel.zipcode"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.zipcode" />
    </div>

    <div
      v-if="
        customerSelectionMethod === CUSTOMER_CHOICE_SELECT && selectedCustomer
      "
      class="customer-details"
    >
      <dl class="row">
        <dt class="col-6">Nom du client :</dt>
        <dd>
          {{ selectedCustomer.firstname + " " + selectedCustomer.lastname }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-6">Couriel :</dt>
        <dd>
          {{ selectedCustomer.email }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-6">Téléphone :</dt>
        <dd>
          {{ selectedCustomer.phone }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-6">Pays :</dt>
        <dd>
          {{ selectedCustomer.country }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-6">Ville :</dt>
        <dd>
          {{ selectedCustomer.city }}
        </dd>
      </dl>
    </div>

    <div class="buttons-group">
      <el-button
        class="continue"
        type="primary"
        :disabled="
          customerSelectionMethod === CUSTOMER_CHOICE_SELECT &&
          !booking.customer.id
        "
        @click="
          () => {
            handleSubmit(true);
          }
        "
      >
        {{ $t("BOOKINGS.SECURE_BOOKING") }}
      </el-button>
      <el-button
        type="secondary"
        class="add"
        :disabled="
          customerSelectionMethod === CUSTOMER_CHOICE_SELECT &&
          !booking.customer.id
        "
        @click="
          () => {
            handleSubmit(false);
          }
        "
      >
        {{ $t("BOOKINGS.ADD_BOOKING") }}
      </el-button>
      <el-button
        class="previous"
        type="primary"
        @click="
          () => {
            gotToStep(0);
          }
        "
      >
        {{ $t("COMMON.PREVIOUS") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Option,
  Radio,
  RadioGroup,
  Row,
  Select,
  Step,
  Steps,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError";
import CustomerSelector from "@/components/CustomerSelector";
import defaultBooking from "../../defaultBooking";
import defaultSpot from "../../../../SpotModule/SpotManagement/defaultSpot";
import CountrySelector from "@/components/CountrySelector.vue";
import { BOOKING_STATUS_PENDING } from "@/constants/common";
import StateSelector from "@/components/StateSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import defaultCustomer from "@/views/Pages/CrmModule/CustomerManagement/defaultCustomer";
import {
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
} from "@/constants/customers";
import swal from "sweetalert2";

const CUSTOMER_CHOICE_SELECT = "SELECT";
const CUSTOMER_CHOICE_CREATE = "CREATE";

export default {
  name: "booking-customer-selection",

  components: {
    CustomerSelector,
    ValidationError,
    CountrySelector,
    StateSelector,
    PhoneNumberInput,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
  },

  data() {
    const customerModel = cloneDeep(defaultCustomer);
    customerModel.organization.id = this.bookingData.organization?.id;

    const booking = {
      ...defaultBooking,
      ...this.bookingData,
      customer: this.bookingData.customer ?? {
        id: customerModel.id,
        type: customerModel.type,
      },
    };

    return {
      booking,
      customerSelectionMethod: CUSTOMER_CHOICE_SELECT,
      customerModel,
      selectedCustomer: null,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      formErrors: null,
      CUSTOMER_CHOICE_SELECT,
      CUSTOMER_CHOICE_CREATE,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit(secure = false) {
      await this.sendInformation(secure);
    },

    async sendInformation(secure = false) {
      this.loading = true;
      swal.showLoading();

      // this.booking.status = BOOKING_STATUS_PENDING;

      try {
        const bookingData = cloneDeep(this.booking);

        delete bookingData.spot;
        delete bookingData.equipment;
        delete bookingData.start_at;
        delete bookingData.end_at;
        delete bookingData.order;
        delete bookingData.created_at;

        bookingData.status = BOOKING_STATUS_PENDING;

        if (this.customerSelectionMethod == this.CUSTOMER_CHOICE_CREATE) {
          const customer = await this.createCustomer();

          bookingData.customer.id = customer.id;

          await this.$store.dispatch("bookings/update", bookingData);
        } else {
          if (isNaN(bookingData.customer.id)) {
            return;
          }

          await this.$store.dispatch("bookings/update", bookingData);
        }

        this.booking = this.$store.getters["bookings/booking"];

        this.$emit(
          "customerSelected",
          this.booking,
          secure,
          this.booking.orderItem.salesOrder
        );
        swal.close();
      } catch (error) {
        swal.close();
        this.formErrors = error.response.data.errors;
      }

      this.loading = false;
    },

    async createCustomer() {
      const customer = cloneDeep(this.customerModel);

      if (customer.billing_entity_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customer.company_name = "N/A";
        customer.billing_company_name = "N/A";
        customer.billing_firstname = "N/A";
        customer.billing_lastname = "N/A";
      } else if (customer.billing_entity_type === CUSTOMER_TYPE_COMPANY) {
        customer.firstname = "N/A";
        customer.lastname = "N/A";
        customer.billing_firstname = "N/A";
        customer.billing_lastname = "N/A";
      }
      customer.billing_country = customer.country ?? "N/A";
      customer.billing_state = customer.state ?? "N/A";
      customer.billing_city = customer.city ?? "N/A";
      customer.billing_zipcode = customer.zipcode ?? "N/A";
      customer.billing_address = customer.address ?? "N/A";
      customer.billing_email = customer.email;

      await this.$store.dispatch("customers/add", customer);

      return this.$store.getters["customers/customer"];
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },

    gotToStep(stepNumber) {
      this.$emit("stepChanged", stepNumber);
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...defaultBooking,
            ...this.booking,
            ...cloneDeep(bookingData),
          };
        }
      },
      deep: true,
    },
  },
};
</script>
