<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>

    <div class="col-12">
      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.BOOKING") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.BOOKING_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ booking.code }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.BOOKING_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ $t(`BOOKINGS.${booking.status}`) }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.ARRIVING_AT") }}
            </dt>
            <dd class="">
              <div>Prévue - {{ $formatDate(booking.start_at, "LL") }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.LEAVING_AT") }}
            </dt>
            <dd class="">
              <div>Prévue - {{ $formatDate(booking.end_at, "LL") }}</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.CUSTOMER") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("CUSTOMERS.LAST_NAME") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.customer.lastname }} {{ booking.customer.firstname }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("CUSTOMERS.EMAIL") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.email }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("CUSTOMERS.PHONE") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.phone }}</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.SPOT") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_NAME") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.spot.name }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.code }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_TYPE") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.spotType?.name }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4" v-if="false">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.SERVICE") }}
            </dt>
            <dd class="">
              <div></div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4" v-if="booking.special_request">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.SPECIAL_REQUEST") }}
            </dt>
            <dd class="">
              <div>{{ booking.special_request }}</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.OCCUPATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.PEOPLE_NUMBER") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.children_count + booking.adults_count }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.adults_count }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.children_count ?? 0 }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_PETS_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.pets_count ?? 0 }}</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.OTHER") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.CREATED_AT") }}
            </dt>
            <dd class="">
              <div>
                {{ $formatDate(booking.created_at, "LL") }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.CREATED_BY") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.createdBy.lastname }}
                {{ booking.createdBy.firstname }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.UPDATED_AT") }}
            </dt>
            <dd class="">
              <div>
                {{ $formatDate(booking.updated_at, "LL") }}
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";

export default {
  name: "booking-view-global",

  props: ["booking"],

  components: { Tags },

  data() {
    return {};
  },

  computed: {
    bookingableName() {
      if (this.booking.bookingable.type == "bookings") {
        return this.booking.bookingable.company_name;
      } else if (this.booking.bookingable.type == "customers") {
        return this.booking.bookingable.customer_name;
      }
      return "N/A";
    },
    bookingableTypeName() {
      if (this.booking.bookingable.type == "bookings") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.booking.bookingable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    bookingUpdated() {
      this.$emit("bookingUpdated", true);
    },
  },

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>

<style lang="scss">
.text-orange {
  --darken-primary: #{darken(#ef8152, 10%)};
  color: --darken-primary;
}
</style>
