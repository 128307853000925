<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("BOOKINGS.BOOKING_SPOT") }}</h3>

    <div class="col-12">
      <div class="mt-3">
        <h3 class="text-orange">{{ $t("BOOKINGS.BOOKING_INFORMATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.BOOKING_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ booking.code }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.STATUS") }}
            </dt>
            <dd class="">
              <div>{{ $t(`BOOKINGS.${booking.status}`) }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.ARRIVING_AT") }}
            </dt>
            <dd class="">
              <div>Prévue - {{ $formatDate(booking.start_at, "LL") }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.LEAVING_AT") }}
            </dt>
            <dd class="">
              <div>Prévue - {{ $formatDate(booking.end_at, "LL") }}</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.OCCUPATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.PEOPLE_NUMBER") }}
            </dt>
            <dd class="">
              <div>
                {{
                  booking.spot.children_capacity + booking.spot.adults_capacity
                }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.adults_capacity }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.children_capacity ?? 0 }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_PETS_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.pets_capacity ?? 0 }}</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="text-orange">{{ $t("BOOKINGS.SPOT_INFORMATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_NAME") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.spot.name }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.code }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_TYPE") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.spotType?.name }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.CATEGORY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.category?.name }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.COMMENT") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.excerpt ?? "-" }}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "booking-view-spot",

  props: ["booking"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>

<style lang="scss">
.text-orange {
  --darken-primary: #{darken(#ef8152, 10%)};
  color: --darken-primary;
}
</style>
