<template>
  <div class="container-fluid">
    <booking-form
      :loading="loading"
      :bookingData="booking"
      :spot="spot"
      :formErrors="formErrors"
      @bookingCreated="onBookingCreated"
      @onCloseBookingModal="closeBookingModal"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultBooking from "../defaultBooking";
import BookingForm from "../partials/BookingForm.vue";
import defaultSpot from "../../../SpotModule/SpotManagement/defaultSpot";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BookingForm,
  },

  mixins: [alertLeave],

  props: {
    spotId: {
      type: [String, Number],
      required: true,
    },
    bookingData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      booking: cloneDeep({ ...defaultBooking, ...this.bookingData }),
      spot: defaultSpot,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.getSpot();
  },

  methods: {
    async getSpot() {
      this.loading = true;
      try {
        await this.$store.dispatch("spots/get", this.spotId);
        this.spot = this.$store.getters["spots/spot"];
        // this.booking.spot = cloneDeep(this.spot);
        this.booking.organization = this.spot.organization;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },
    onBookingCreated(booking, secure, order) {
      if (secure) {
        this.$emit("onOpenConfirmBookingModal", order);
      } else {
        this.$emit("onCloseBookingModal", true);

        // this.$router.push({
        //   name: "New Booking",
        //   query: { id: booking.id, action: "view" },
        // });
      }

      // if (!secure) {
      // } else {
      //   history.pushState(
      //     {},
      //     null,
      //     this.$router.resolve({
      //       name: "Pending Bookings",
      //       query: { id: booking.id, action: "confirm" },
      //     }).href
      //   );
      // }
    },
    closeBookingModal() {
      this.$emit("onCloseBookingModal", true);
    },
  },

  watch: {
    bookingData: function () {
      this.booking = cloneDeep({ ...this.booking, ...this.bookingData });
    },
  },
};
</script>
